import React from "react"
import HTMLHead from "../components/HTMLHead"

const IndexPage = () => {
  return null
}

export default IndexPage

export const Head = () => <HTMLHead pageTitle="Home" />
